import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

import Slide from "components/features/TwoColWithImage.js";
import { SectionHeading as Heading, } from "components/misc/Headings.js";

import Image1 from "../images/pages/international/1.jpg";
import Image2 from "../images/pages/international/2.jpg";

const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0 text-center`;
const PrimaryButton = styled(PrimaryButtonBase)(tw`mt-4 py-3 md:mt-4 text-sm inline-block md:mx-4 w-48`);
const Description = tw.p`mt-4 text-sm lg:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

export default () => {
  return (
    <>
      <div className='pb-0 max-w-screen-xl mx-auto pt-20 lg:pt-24'>
        <Heading>Şcoli de Vacanţă Internaţionale</Heading>
        <Description>
          <p className='pb-4 pt-6 text-center'>
            Vă întrebați cum să organizați vacanța copiilor sau chiar a Dvs. cât mai interesant și eficient, beneficiind atât de o <strong>experiență memorabilă</strong>, cât și de <strong>oportunități de creștere și dezvoltare</strong> prin distracție, sport, socializare și călătorii?
          </p>
          <p className='pb-4 text-center'>
            Menționăm că școlile internaționale <strong>sunt dedicate atât copiilor, cât și adulților</strong>. Comunicați-ne propriile nevoi și preferințe completând formularul de mai jos, iar noi vom reveni telefonic și prin e-mail cu oferta optimă din toate punctele de vedere și perfect racordată la așteptările Dvs.
          </p>
          <p className='text-center'>
            Accesați butonul destinat statutului Dvs. și ajutați-ne să vă sugerăm cel mai bun program de vacanță pentru copii și pentru adulți.
          </p>
        </Description>
        <TwoColumn>
          <Column>
            <PrimaryButton as="a" target="_blank" href='https://forms.office.com/e/WgVQ8HXmdt'>
              Formular adulți
            </PrimaryButton>
          </Column>
          <Column>
            <PrimaryButton as="a" target="_blank" href='https://forms.office.com/e/qDwX7crkr2'>
              Formular copii
            </PrimaryButton>
          </Column>
        </TwoColumn>
      </div>
      <Slide
        subheading=''
        heading="Oferta ISCEO"
        description={
          <>
            <p className='pb-4'>
              Programele internaționale pe care le susținem presupun călătorii și excursii în importante centre lingvistice din străinătate, ce au ca scop familiarizarea cursanților cu istoria și cultura țării respective, dar și extinderea ariei de confort și a nivelului de dezvoltare personală ale copiilor și adulților. Ne bazăm pe <strong>îmbinarea coerentă a distracției cu educația</strong>, creând astfel oportunități de studiu al unei limbi străine, într-un mediu cultural și social autentic și în cadrul lecțiilor organizate de <strong>profesori vorbitori nativi</strong>.
            </p>
            <p>
              Organizăm, anual, școli de vară pentru copii (9-19 ani) și adulți în Marea Britanie, Europa și SUA. Optând pentru serviciile ISCEO, aveți oportunitatea de a găsi compromisul ideal între vacanță și studiu, învățând și exersând sistematic o limbă străină prin relaxare, distracție și excursii de excepție.
            </p>
          </>
        }
        imageSrc={Image1}
      />
      <Slide
        subheading=''
        heading="Angajament"
        description={
          <>
            <p className='pb-4'>
              Din 2008, ne asumăm responsabilitatea de a susține părinții în <strong>pregătirea setului de acte necesar</strong> pentru călătorie și de a-i însoți pe copii în cadrul programului (în cazul călătoriei în grup), factor care, din experiența noastră, asigură un <strong>climat psihologic favorabil</strong> atât pentru copii, cât și pentru părinți.
            </p>
            <p className='pb-4'>
              La finalul programului, participanții vor obține un <strong>certificat</strong> ce atestă nivelul de competență și performanță lingvistică, reflectând astfel progresele obținute în cadrul programului nostru.
            </p>
            <p>
              Aveți posibilitatea de a selecta varianta optimă în funcție de limba dorită, vârsta copilului, țara preferată și alți factori-cheie care ar configura portofoliul programului căutat.
            </p>
          </>
        }
        imageSrc={Image2}
        textOnLeft={false}
      />
    </>
  );
};
