
import React from "react";
import QuestionSlide from "components/faqs/ExpandingText.js";

import Image1 from "../images/pages/isceo-brand/1.jpg";
import Image2 from "../images/pages/isceo-brand/2.jpg";


export default () => {
  return (
    <>
      <QuestionSlide
        imageSrc={Image1}
        subheading=""
        heading="Marca ISCEO"
        description={
          <p>
            Beneficiile oferite în cadrul experienței ISCEO au devenit, de-a lungul timpului, o <strong>marcă a calității actului educațional</strong>, a relațiilor de încredere reciprocă și parteneriat temeinic <strong>profesor-elev-părinte</strong>, a inovației și performanței în materie de predare a Limbii Engleze în tandem cu Psihologia. Astfel, la ISCEO veți profita de:
          </p>
        }
        faqs={[
          {
            question: "Testarea nivelului de dezvoltare a competențelor lingvistice",
            answer:(
              <p>
                Este primul pas în abordarea individuală a fiecărui elev. De regulă, se administrează un <strong>test scris</strong> și un <strong>interviu</strong>, în funcție de vârsta solicitantului.
              </p>
            )
          },
          {
            question: "Principialitate în formarea grupurilor",
            answer:(
              <p>
                <strong>Vârsta</strong> și <strong>nivelul de dezvoltare al competențelor lingvistice</strong> sunt criterii fundamentale care stau la baza formării grupurilor de studiu.
              </p>
            )
          }
        ]}

      />
      <QuestionSlide
        imageSrc={Image2}
        subheading=""
        heading=""
        description=''
        textOnLeft={false}
        faqs={[
          {
            question: "Monitorizarea sistematică a progresului cursanților",
            answer:(
              <p>
                ISCEO măsoară, la finele fiecărui modul, progresul înregistrat de cursanți în baza materiei studiate, cu scopul de a oferi <strong>feedback regulat</strong> și de a menține calitatea procesului instructiv-educativ.
              </p>
            )
          },
          {
            question: "Învățarea în împrejurări cât mai naturale și autentice",
            answer:(
              <p>
                Mediul de imersiune lingvistică creat în cadrul programelor noastre le oferă elevilor multiple posibilități de practicare a limbii engleze în contexte cotidiene, autentice, unde, pe lângă exersarea normelor gramaticale, se pune accent pe <strong>dezvoltarea capacităților conversaționale</strong>.
              </p>
            )
          },
          {
            question: "Limba Engleză cu Personalitate",
            answer:(
              <p>
                Metodologia de lucru în cadrul cursurilor integrează tehnici moderne și inovative de predare a unei limbi străine, prin deplasarea punctului de greutate pe trăsăturile particulare ale fiecărui participant, din puncte de vedere psihologic, emoțional, social. Practic, <strong>limba engleză este adaptată la personalitatea elevului</strong>.
              </p>
            )
          },
          {
            question: "Centru multifuncțional și dotări performante",
            answer:(
              <p>
                Punem la dispoziția cursanților săli de clasă spațioase, confortabile, complet amenajate și dotate cu <strong>echipamente tehnologice performante</strong>, <strong>table interactive</strong> pentru lucrul în clasă și pentru predarea la distanță, <strong>teren de sport</strong> pentru activități în aer liber și <strong>spațiu de joacă pentru copii</strong>. Dispunem de numeroase materiale de studiu, <strong>literatură editată la Oxford și Cambridge</strong>, <strong>jocuri educative și de societate</strong>, precum și toate resursele birotice folosite în cadrul cursurilor curriculare și extracurriculare.
              </p>
            )
          }
        ]}
      />
    </>
  );
};
