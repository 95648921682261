import React from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { Container } from "components/misc/Layouts.js";

const TwoColumn = tw.div`flex flex-col lg:flex-row justify-between max-w-screen-xl mx-auto py-20 lg:py-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto lg:max-w-none lg:mx-0`;
const ImageColumn = tw(Column)`lg:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
  tw`lg:w-6/12 mt-16 lg:mt-0`,
  props.textOnLeft ? tw`lg:mr-12 lg:mr-16 lg:order-first` : tw`lg:ml-12 lg:ml-16 lg:order-last`
]);

const FAQContent = tw.div`lg:ml-12`;
const Subheading = tw(SubheadingBase)`mb-4 text-center lg:text-left`;
const Heading = tw(SectionHeading)`lg:text-left`;
const Description = tw.p`max-w-xl text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-secondary-100`;

const FAQSContainer = tw.dl`mt-12`;
const FAQ = tw.div`cursor-pointer mt-8 select-none border lg:border-0 px-8 py-4 lg:p-0 rounded-lg lg:rounded-none`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;

const Answer = motion(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);

export default ({
  subheading = "",
  heading = "Questions",
  description = "Here are some frequently asked questions about our hotels from our loving customers. Should you have any other questions, feel free to reach out via the contact form below.",
  imageSrc = "https://images.unsplash.com/photo-1579427421635-a0015b804b2e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
  textOnLeft = true,
  question,
  backgroundClass = '',
  answer
}) => {

  return (
    <Container className={backgroundClass}>
      <TwoColumn>
        <ImageColumn>
          <img src={imageSrc} alt='imagine' />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <FAQContent>
            {subheading ? <Subheading>{subheading}</Subheading> : null}
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <FAQSContainer>
              <FAQ className="group">
                <Question className='pb-4'>
                  <QuestionText>{question}</QuestionText>
                </Question>
                <Answer>
                  {answer}
                </Answer>
              </FAQ>
            </FAQSContainer>
          </FAQContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
