import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { Container } from "components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as PlusIcon } from "feather-icons/dist/icons/plus.svg";
import { ReactComponent as MinusIcon } from "feather-icons/dist/icons/minus.svg";

const TwoColumn = tw.div`flex flex-col lg:flex-row justify-between max-w-screen-xl mx-auto py-20 lg:py-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto lg:max-w-none lg:mx-0`;
const ImageColumn = tw(Column)`lg:w-6/12 flex-shrink-0 relative`;
const ButtonContainer = tw.div`flex flex-col lg:items-end items-center mb-5`;
const TextColumn = styled(Column)(props => [
  tw`lg:w-6/12 mt-16 lg:mt-0`,
  props.textOnLeft ? tw`lg:mr-12 lg:mr-16 lg:order-first` : tw`lg:ml-12 lg:ml-16 lg:order-last`
]);

const FAQContent = tw.div`lg:ml-12`;
const Subheading = tw(SubheadingBase)`mb-4 text-center lg:text-left`;
const Heading = tw(SectionHeading)`lg:text-left`;
const Description = tw.p`max-w-xl text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-secondary-100`;

const FAQSContainer = tw.dl`mt-12`;
const FAQ = tw.div`cursor-pointer mt-8 select-none border lg:border-0 px-8 py-4 lg:p-0 rounded-lg lg:rounded-none`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = styled.span`
  ${tw`ml-2 bg-primary-500 text-gray-100 p-1 rounded-full group-hover:bg-primary-700 group-hover:text-gray-200 transition duration-300`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const Answer = motion(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);
const PrimaryButton = styled(PrimaryButtonBase)(tw`mt-4 py-3 md:mt-4 text-sm inline-block md:mx-4 w-48`);

export default ({
  subheading,
  heading,
  description,
  imageSrc,
  textOnLeft = true,
  faqs = null,
  backgroundClass = '',
  buttonSrc
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <Container className={backgroundClass}>
      <TwoColumn>
        <ImageColumn>
          {buttonSrc && (<ButtonContainer>
            <PrimaryButton as="a" href={buttonSrc} className='text-center' target='_blank'>
              Aplică acum
            </PrimaryButton>
          </ButtonContainer>)}
          <img src={imageSrc} alt='imagine' />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
            <FAQContent>
              {subheading ? <Subheading>{subheading}</Subheading> : null}
              <Heading>{heading}</Heading>
              <Description>{description}</Description>
              <FAQSContainer>
                {faqs.map((faq, index) => (
                  <FAQ
                    key={index}
                    onClick={() => {
                      toggleQuestion(index);
                    }}
                    className="group"
                  >
                    <Question>
                      <QuestionText>{faq.question}</QuestionText>
                      <QuestionToggleIcon>
                        {activeQuestionIndex === index ? <MinusIcon /> : <PlusIcon />}
                      </QuestionToggleIcon>
                    </Question>
                    <Answer
                      variants={{
                        open: { opacity: 1, height: "auto", marginTop: "16px" },
                        collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                      }}
                      initial="collapsed"
                      animate={activeQuestionIndex === index ? "open" : "collapsed"}
                      transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >
                      {faq.answer}
                    </Answer>
                  </FAQ>
                ))}
              </FAQSContainer>
            </FAQContent>
          </TextColumn>
      </TwoColumn>
    </Container>
  );
};
