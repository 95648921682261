import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Container } from "components/misc/Layouts.js";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";

const TwoColumn = tw.div`flex flex-col lg:flex-row justify-between max-w-screen-xl mx-auto py-20 lg:py-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto lg:max-w-none lg:mx-0`;
const ImageColumn = tw(Column)`lg:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)((props) => [
  tw`lg:w-6/12 mt-16 lg:mt-0`,
  props.textOnLeft
    ? tw`lg:mr-12 lg:mr-16 lg:order-first`
    : tw`lg:ml-12 lg:ml-16 lg:order-last`,
]);

const TextContent = tw.div`lg:py-8 text-center lg:text-left`;

const Subheading = tw(
  SubheadingBase
)`text-center lg:text-left uppercase tracking-wider text-sm`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center lg:text-left leading-tight`;
const Description = tw.div`mt-4 text-center lg:text-left text-sm lg:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

export default ({
  subheading = "",
  heading,
  description,
  imageSrc,
  textOnLeft = true,
  backgroundClass = "",
  textClass = "",
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <Container className={backgroundClass}>
      <TwoColumn>
        <ImageColumn>
          <img src={imageSrc} alt="imagine" />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading className={textClass}>{heading}</Heading>
            <Subheading>{subheading}</Subheading>
            <Description>{description}</Description>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
