import React from "react";
import Slide from "components/features/TwoColWithImage.js";
import StepSlide from "components/features/ImageWithSteps.js";
import QuestionSlide from "components/faqs/ExpandingText.js";
import tw from "twin.macro";
import styled from "styled-components";
import {
  PrimaryButton as PrimaryButtonBase,
} from "components/misc/Buttons.js";

import Image1 from "../../images/pages/english-afternoons/1.jpg";
import Image2 from "../../images/pages/english-afternoons/2.jpg";
import Image3 from "../../images/pages/english-afternoons/3.jpeg";
import { ENGLISH_AFTERNOONS_LINK } from "../../constants";

const PrimaryButton = styled(PrimaryButtonBase)((props) => [
  tw`mt-4 md:mt-4 text-xl inline-block mx-auto md:mx-0 w-full text-center`,
  props.buttonRounded && tw`rounded-full`,
]);

const ButtonContainer = tw.div`max-w-xs mx-auto flex items-center mt-2 mb-8`;

export default () => {
  return (
    <>
      <Slide
        subheading=''
        heading="English Afternoons"
        description={
          <>
            <p className='pb-4'>
              Acest program de studiere a Limbii Engleze, pe parcursul întregului an academic
              (septembrie-mai) este modalitatea perfectă de a oferi copilului o <strong>pregătire
              lingvistică temeinică</strong>. Acest program îmbină armonios principiile și rigoarea
              specifice <strong>Programului Academic</strong> și formatul prietenos vârstei al <strong>Programului
              de Weekend</strong>.
            </p>
            <p>
              English Afternoons, așa cum ne este specific la ISCEO, este un program
              cu <strong>Personalitate unică</strong>, ca și idee, concept și format. Fiecare sesiune
              săptămânală a programului îi va teleporta pe elevi pe un tărâm al <strong>Englezei
              cărturare și autentice</strong>. Ghidați de un profesor empatic, dedicat și profesionist,
              aceștia vor deprinde, de la o vârstă fragedă, <strong>bazele Limbii Engleze</strong>, la
              nivel de <strong>percepție</strong>, <strong>înțelegere</strong>,
              <strong>gândire</strong> și, în mod esențial, <strong>vorbire</strong>.
            </p>
            <ButtonContainer>
            <PrimaryButton as="a" href={ENGLISH_AFTERNOONS_LINK}>
              Înregistrare '24-'25
            </PrimaryButton>
          </ButtonContainer>
          </>
        }
        imageSrc={Image1}
      />
      <StepSlide
        subheading=''
        heading={
          <>
            Mod de organizare
          </>
        }
        textOnLeft={false}
        imageSrc={Image2}
        steps={[
          {
            heading: "Vârsta",
            description: "6-12 ani"
          },
          {
            heading: "Perioada de realizare",
            description: "septembrie - mai, cu respectarea tuturor vacanțelor școlare"
          },
          {
            heading: "Desfășurare",
            description: "1-2 sesiuni (întâlniri), pe săptămână, în funcție de preferințele familiei.\nOrar flexibil, orientativ 14:00-18:00"
          },
          {
            heading: "Alimentație",
            description: "prânz și/sau chindii, opțional"
          },
          {
            heading: "Număr de cursanți în grup",
            description: "5-8 cursanți"
          },
        ]}
      />
      <QuestionSlide
        imageSrc={Image3}
        subheading=""
        heading='Particularitățile cursului'
        description=''
        faqs={[
          {
            question: "Literatura editată de Oxford și Cambridge",
            answer:(
              <p>
                Nenumăratele materiale didactice din librăria ISCEO sunt o resursă complementară eforturilor profesioniștilor noștri și un instrument valoros în activitatea pe care o desfășurăm. Manualele însoțite de caiete de lucru, CD-uri și discuri audio/video trezesc și mențin optimul motivațional al elevilor, pe toată durata cursului. Mai mult, acestea reprezintă o resursă prețioasă pentru activitatea independentă, în afara orelor de curs.
              </p>
            )
          },
          {
            question: "Format prietenos vârstei",
            answer:(
              <p>
                La început de cale în învățarea unei limbi străine, implicarea unui profesor dedicat și atent la nevoile particulare ale fiecărui elev este esențială. Activitățile scurte și interactive, alternate cu pauze relaxante le vor permite copiilor să-și mențină concentrarea și atenția maxime, în timpul lecțiilor, cât și să aplice cele învățate teoretic, în timpul pauzelor, într-o atmosferă plăcută și prietenoasă.
              </p>
            )
          },
          {
            question: "Conținut academic calitativ",
            answer:(
              <p>
                Fiecare sesiune de program va asigura elevilor 4 ore de Engleză de calitate, în cadrul unui curs captivant și atractiv. Având la dispoziție facilități moderne și tehnici inovatoare, aspectele lingvistice academice sunt armonios diluate de activități interactive și interesante, pentru copii.
              </p>
            )
          }
        ]}
      />
      <ButtonContainer style={{marginTop: '-3rem'}}>
        <PrimaryButton as="a" href={ENGLISH_AFTERNOONS_LINK}>
          Înregistrare '24-'25
        </PrimaryButton>
      </ButtonContainer>
    </>
  );
};
