import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
// import { css } from "styled-components/macro"; //eslint-disable-line
// import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

import Logo from "../../images/isceo-logo.svg";
import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as InstagramIcon } from "images/instagram-icon.svg";

const Container = tw.div`relative bg-gray-200 text-gray-700 -mb-8 px-8 py-20 lg:py-24`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;
const SixColumns = tw.div`flex flex-wrap text-center sm:text-left justify-center sm:justify-start md:justify-between -mt-12`;

const Column = tw.div`px-4 sm:px-0 sm:w-1/4 md:w-auto mt-12`;

const ColumnHeading = tw.h5`uppercase font-bold`;

const LinkList = tw.ul`mt-6 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:border-gray-700 pb-1 transition duration-300`;

// const SubscribeNewsletterColumn = tw(Column)`text-center lg:text-left w-full! lg:w-auto! mt-20 lg:mt-12`;
// const SubscribeNewsletterContainer = tw.div`max-w-sm mx-auto lg:mx-0 `;
// const SubscribeText = tw.p`mt-2 lg:mt-6 text-sm font-medium text-gray-600`;
// const SubscribeForm = tw.form`mt-4 lg:mt-6 text-sm sm:flex max-w-xs sm:max-w-none mx-auto sm:mx-0`;
// const Input = tw.input`bg-gray-300 px-6 py-3 rounded sm:rounded-r-none border-2 sm:border-r-0 border-gray-400 hover:border-primary-500 focus:outline-none transition duration-300 w-full`;
// const SubscribeButton = tw(PrimaryButtonBase)`mt-4 sm:mt-0 w-full sm:w-auto rounded sm:rounded-l-none px-8 py-3`;

const Divider = tw.div`my-16 border-b-2 border-gray-300 w-full`;

const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between`;

const LogoImg = tw.img`flex items-center justify-center md:justify-start w-24`;
// const LogoImg = tw.img`w-8`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-500`;

const SocialLinksContainer = tw.div`mt-8 md:mt-0 flex`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-gray-900 text-gray-100 hover:bg-gray-700 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

export default () => {
  return (
    <Container>
      <Content>
        <SixColumns>
          <Column>
            <ColumnHeading>Principal</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/">Acasă</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/about">Cine suntem</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/history">Istoric</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/isceo-brand">Marca ISCEO</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/careers">Cariere</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/contact">Contact</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Programe MD</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/md-programmes/academic-program">
                  Programul Academic
                </Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/md-programmes/weekend-school">
                  Weekend School
                </Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/md-programmes/english-afternoons">
                  English Afternoons
                </Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/md-programmes/summer-school">
                  Școala de Vară
                </Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/md-programmes/pupils-workshop">
                  Atelierul Preșcolarului
                </Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/md-programmes/parents-academy">
                  Academia Părinților
                </Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Internațional</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/international">Despre Internațional</Link>
              </LinkListItem>
              <LinkListItem>
                <Link target="_blank" href="https://forms.office.com/e/WgVQ8HXmdt">
                  Formular adulți
                </Link>
              </LinkListItem>
              <LinkListItem>
                <Link target="_blank" href="https://forms.office.com/e/qDwX7crkr2">
                  Formular copii
                </Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>ASSIST/HMCP</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/hmc-assist/hmc">Despre HMC</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/hmc-assist/assist">Despre ASSIST</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/hmc-assist/hmc-offer">Aplică la HMC</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/hmc-assist/assist-offer">Aplică la ASSIST</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          {/* <SubscribeNewsletterColumn>
            <SubscribeNewsletterContainer>
              <ColumnHeading>Subscribe to our Newsletter</ColumnHeading>
              <SubscribeText>
                We deliver high quality blog posts written by professionals weekly. And we promise no spam.
              </SubscribeText>
              <SubscribeForm method="get" action="#">
                <Input type="email" placeholder="Your Email Address" />
                <SubscribeButton type="submit">Subscribe</SubscribeButton>
              </SubscribeForm>
            </SubscribeNewsletterContainer>
          </SubscribeNewsletterColumn> */}
        </SixColumns>
        <Divider />
        <div className='flex justify-center items-center gap-20'>
          <span id='iasBadge' data-account-id='5912'></span>
          <span>
            <a href='https://www.englishuk.com/' target='_blank' rel="noreferrer">
              <img src='https://www.englishuk.com/images/2019/English%20UK%20logo%20RGB%202018%201000w.png' alt='English UK' style={{height: '120px'}} />
            </a>
          </span>
          <span>
            <a href='https://usm.md' target='_blank' rel="noreferrer">
              <img src='https://usm.md/wp-content/uploads/cropped-logo_dark_22.png' alt='Universitatea de Stat din Moldova' style={{height: '120px'}} />
            </a>
          </span>
        </div>
        <ThreeColRow>
          <a href="/">
            <LogoImg src={Logo} className="not-round" />
          </a>
          <CopywrightNotice>
            &copy; {new Date().getFullYear()} GuD Software. All Rights Reserved.
          </CopywrightNotice>
          <SocialLinksContainer>
            <SocialLink
              href="https://www.facebook.com/isceo.md"
              target="_blank"
            >
              <FacebookIcon />
            </SocialLink>
            <SocialLink
              href="https://www.instagram.com/isceo.md/"
              target="_blank"
            >
              <InstagramIcon />
            </SocialLink>
          </SocialLinksContainer>
        </ThreeColRow>
      </Content>
    </Container>
  );
};
