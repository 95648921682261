import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Container } from "components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";

const TwoColumn = tw.div`flex flex-col lg:flex-row justify-between max-w-screen-xl mx-auto pb-20 lg:pb-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto lg:max-w-none lg:mx-0`;
const TextColumn = styled(Column)(props => [
  tw`lg:w-4/12 mt-16 lg:mt-0`,
  props.textOnLeft ? tw`lg:mr-12 lg:mr-16 lg:order-first` : tw`lg:ml-12 lg:ml-16 lg:order-last`
]);

const TextContent = tw.div`lg:py-8 lg:text-left`;

const Subheading = tw(SubheadingBase)`text-center lg:text-left uppercase tracking-wider text-sm`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-3xl sm:text-4xl lg:text-5xl text-center leading-tight mt-10`;
const Description = tw.p`mt-4 lg:text-left text-sm lg:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

export default ({
  subheading,
  heading,
  backgroundClass = '',
  description,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <Container className={backgroundClass}>
      <Heading>{heading}</Heading>
      <TwoColumn>
        <TextColumn>
          <TextContent>
            <Subheading>{subheading}</Subheading>
            <Description>{description}</Description>
          </TextContent>
        </TextColumn>
        <TextColumn>
          <Description>
            <div className='w-3/12 inline-block align-top'>
              <strong>Program:</strong>
            </div>
            <div className='w-9/12 inline-block'>
              <p>Luni: 9:00-18:00</p>
              <p>Marți: 9:00-18:00</p>
              <p>Miercuri: 9:00-18:00</p>
              <p>Joi: 9:00-18:00</p>
              <p>Vineri: 9:00-18:00</p>
              <p>Sâmbătă: 9:00-15:00</p>
              <p>Duminică: închis</p>
            </div>
          </Description>
        </TextColumn>
        <TextColumn>
          <Description>
          <div className='w-3/12 inline-block align-top'>
              <strong>Adresa:</strong>
            </div>
            <div className='w-9/12 inline-block'>
              <p>Str. Semion Timoșenko 10,</p>
              <p>Chișinău,</p>
              <p>R. Moldova,</p>
              <p>MD-2049</p>
            </div>
          </Description>
        </TextColumn>
      </TwoColumn>
      <TwoColumn>
        <iframe className='w-full h-80' title='ISCEO map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2718.084665760173!2d28.793884415679432!3d47.05818947915211!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97d80ed675849%3A0x1391a377b281a8e8!2sISCEO!5e0!3m2!1sro!2sro!4v1630949889390!5m2!1sro!2sro" allowfullscreen="" loading="lazy" />
      </TwoColumn>
    </Container>
  );
};
