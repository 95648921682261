import React from "react";
import Slide from "components/features/TwoColWithImage.js";
import StepSlide from "components/features/ImageWithSteps.js";

import Image1 from "../../images/pages/parents-academy/1.jpg";
import Image2 from "../../images/pages/parents-academy/2.jpg";
import Image3 from "../../images/pages/parents-academy/3.jpg";
import Image4 from "../../images/pages/parents-academy/4.jpg";
import Image5 from "../../images/pages/parents-academy/5.jpg";


export default () => {
  return (
    <>
      <Slide
        subheading=''
        heading='Academia Părinților'
        description={
          <>
            <p className='pb-4'>
              <strong>Meseria de părinte</strong> este cea mai importantă dintre toate meseriile pe care le avem în viaţă, şi totuşi, puţini dintre noi încep să profeseze în acest domeniu fiind instruiţi în prealabil. Răspunderea care cade pe umerii noştri, a părinţilor, este una imensă: suntem direct responsabili de spectrul emoţional trăit de copiii noştri încă din primele clipe ale vieţii lor, de modul lor de reacţionare la solicitările externe, de construirea şi menţinerea relaţiilor cu ceilalţi, de conferirea unui anumit sens evenimentelor ce se produc atât în mediul social, cât şi în cel intrapersonal.
            </p>
            <p>
              Părintele este modelul pe care copilul îl urmează, este cel care îi asigură copilului condiţiile de viaţă, îi oferă securitatea afectivă şi educaţia de bază. Practic, de modul în care părintele îşi îndeplineşte rolul depinde viitorul copilului. Şi să nu uităm: <strong>Copiii învaţă ceea ce trăiesc</strong>.
            </p>
          </>
        }
        imageSrc={Image1}
      />
      <Slide
        subheading=''
        heading=''
        description={
          <>
            <p className='pb-4'>
              Într-o lume ideală, copiii vor face exact ceea ce le cerem, atunci când le cerem, şi se vor opri din ceea ce nu vrem să facă în clipa în care i-am rugat acest lucru. În realitate, copiii ne vor pune la încercare mai mult decât credem.
            </p>
            <p>
              Suntem bucuroşi să vă oferim suportul nostru în însuşirea <strong>Artei de a fi Părinte</strong>. Venim în sprijinul Dvs. cu o serie de servicii, menite să vă ajute să surmontați grijile și angoasele părintești și să retrăiți cu siguranță și satisfacție minunatul proces de <strong>creștere</strong>, <strong>socializare</strong> și <strong>educare</strong> al copilului.
            </p>
          </>
        }
        imageSrc={Image2}
        textOnLeft={false}
      />
      <Slide
        subheading=''
        heading='Training formativ/informativ'
        description={
          <>
            <p className='pb-4'>Program axat pe <strong>dezvoltarea unor relaţii părinte-copil armonioase</strong>, pe 7 module:</p>
            <ul className='text-left list-disc pl-5'>
              <li>Iubeşte-ţi copilul inteligent;</li>
              <li>Comunicare eficientă cu copiii;</li>
              <li>Ajută-ţi copilul să-şi învingă frica;</li>
              <li>Cum să-l asculţi şi să te faci ascultat de adolescent;</li>
              <li>În căutarea compromisului;</li>
              <li>Întrebări deocheate despre tot şi despre toate;</li>
              <li>Managementul timpului.</li>
            </ul>
          </>
        }
        imageSrc={Image3}
      />
      <StepSlide
        subheading=''
        heading='Mod de organizare'
        textOnLeft={false}
        imageSrc={Image4}
        steps={[
          {
            heading: 'Participanţi',
            description: 'părinţi, viitori părinţi, adulţi implicaţi în educarea copiilor şi adolescenţilor'
          },
          {
            heading: 'Tip de activitate',
            description: 'training psihologic'
          },
          {
            heading: 'Durata unei şedinţe',
            description: '3 ore, un curs săptămânal'
          },
          {
            heading: 'Durata unui modul',
            description: '4-5 săptămâni, în perioada octombrie - mai'
          },
          {
            heading: 'Număr maxim de cursanți în grup',
            description: '12 cursanți'
          }
        ]}
      />
      <Slide
        subheading=''
        heading='Consiliere psihologică individuală şi de familie'
        description={
          <>
            <p className='pb-4'>
              Oferim sesiuni de consiliere psihologică pentru părinți, pentru a-i susține în procesul relațional cu copiii lor și a stabili conexiuni familiale sănătoase, armonioase și viabile.
            </p>
            <p>Pentru a programa o ședință, ne puteți contacta pe unul dintre <a href="/contact">canalele de comunicare</a>.</p>
          </>
        }
        imageSrc={Image5}
      />
    </>
  );
};
