import React from "react";
import Slide from "components/features/TwoColWithImage.js";
import StepSlide from "components/features/ImageWithSteps.js";
import Particularities from "components/faqs/OneParticularity.js";

import Image1 from "../../images/pages/pupils-workshop/1.jpg";
import Image2 from "../../images/pages/pupils-workshop/2.jpg";
import Image3 from "../../images/pages/pupils-workshop/3.jpg";

export default () => {
  return (
    <>
      <Slide
        subheading=''
        heading="Atelierul Preșcolarului"
        description={
          <div>
            <p className="quote">
              A îndrăzni să cunoști, sau a îndrăzni să te cunoști înseamnă, în primul rând, a-ţi permite libertatea de a accepta un gând nou, de a accepta că imposibilul de ieri poate deveni posibil, acesta fiind de fapt și adevăratul sens al evoluției - <strong>libertatea de a te depăși în fiecare clipă</strong>.
            </p>
            <p className='author pb-4'>
              Ghidul cadrelor didactice pentru educaţia timpurie şi preşcolară
            </p>
            <p>
              Atelierul Preșcolarului este un program care realizează <strong>două direcții de dezvoltare</strong> a copilului: formarea competențelor lingvistice de utilizare a Limbii Engleze și pregătirea psihologică a copilului pentru școală.
            </p>
          </div>
        }
        imageSrc={Image1}
      />
      <StepSlide
        subheading=''
        heading={
          <>
            Mod de organizare
          </>
        }
        textOnLeft={false}
        imageSrc={Image2}
        steps={[
          {
            heading: "Vârsta",
            description: "5-7 ani"
          },
          {
            heading: "Perioada de realizare",
            description: "septembrie - mai, cu pauze pe parcursul vacanțelor școlare"
          },
          {
            heading: "Desfășurare",
            description: "09:00-12:00, câte două ședințe pe săptămână"
          },
          {
            heading: "Număr maxim de cursanți în grup",
            description: "6-7 cursanți"
          },
          {
            heading: "Durata minimă de participare",
            description: "1 semestru"
          },
        ]}
      />
      <Particularities
        imageSrc={Image3}
        subheading=""
        heading='Particularitățile cursului'
        description=''
        question= "Învățăm prin comunicare și joc"
        answer={
          <div>
            <p className='pb-4'>
              Mediul de imersiune lingvistică creat în cadrul Atelierului Preșcolarului le oferă elevilor noștri <strong>multiple posibilități de practicare a limbii engleze</strong> sub ghidarea profesorilor autohtoni.
            </p>
            <p>
              <strong>Caracterul LUDIC</strong> al programului se respectă prin practicarea de jocuri educative și de societate în care sunt implicați cursanții:  de masă, de echipă, organizate în sălile de clasă, în aer liber și în cadrul atelierelor psihologice.
            </p>
          </div>
        }
      />
    </>
  );
};
