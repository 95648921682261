import React from "react";
import { Switch, Route } from "react-router-dom";

import tw from "twin.macro";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import Slide from "components/features/TwoColWithQuote.js";

import HMC from "./hmc-assist/HMC.js";
import ASSIST from "./hmc-assist/ASSIST.js";
import HMCOffer from "./hmc-assist/HMCOffer.js";
import ASSISTOffer from "./hmc-assist/ASSISTOffer.js";
import AlaProfile from "./../images/ala-profile.jpg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;

export default () => {
  return (
    <Switch>
      <Route path="/hmc-assist/hmc-offer">
        <HMCOffer />
      </Route>
      <Route path="/hmc-assist/assist-offer">
        <ASSISTOffer />
      </Route>
      <Route path="/hmc-assist/hmc">
        <HMC />
      </Route>
      <Route path="/hmc-assist/assist">
        <ASSIST />
      </Route>
      <>
        <Testimonial heading="Burse Internaționale" />
        <Slide
          subheading={<Subheading>Reprezentanța în R. Moldova</Subheading>}
          heading=""
          description={
            <>
              <p className="pb-4">
                HMC și ASSIST coordonează programele generale din Marea
                Britanie, respectiv SUA și, din 2021,{" "}
                <strong>
                  colaborează cu ISCEO în vederea selectării candidaților
                </strong>{" "}
                din R. Moldova. ISCEO organizează interviuri cu elevii și oferă
                sprijin candidaților înainte de plecarea lor în Marea Britanie
                sau SUA.
              </p>
              <p className="pb-4">
                Ala Guțu, președinte al ISCEO face parte din familia HMC încă
                din 2002, în calitate de profesor la King's Ely, Marea Britanie
                (2002-2003), iar din 2014, a devenit parte a rețelei globale a
                ASSIST, în calitate de părinte al unui bursier.
              </p>
              <p>
                ISCEO, prin echipa, membrii și susținătorii săi, contribuie la{" "}
                <strong>propagarea valorilor și misiunii HMC și ASSIST</strong>,
                aceea de a oferi elevilor din întreaga lume o educație aleasă,
                de înaltă calitate, în cele mai bune școli britanice și
                americane.
              </p>
            </>
          }
          profileImageSrc={AlaProfile}
          quote="I truly believe these projects to be life changing. That's the reason, why I shall enthusiastically contribute to this cause and help the Moldovan students fulfill their dreams. Thanks a lot for your trust!"
          name="Ala Guțu"
          title="Președinte ISCEO"
        />
      </>
    </Switch>
  );
};
