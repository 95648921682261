import React from "react";
import Slide from "components/features/TwoColWithImage.js";
import QuestionSlide from "components/faqs/ExpandingText.js";

import Image1 from "../images/pages/about/1.jpg";
import Image2 from "../images/pages/about/2.jpg";
import Image3 from "../images/pages/about/3.jpg";
import Image4 from "../images/pages/about/4.jpg";
import Image5 from "../images/pages/about/5.jpg";

export default () => {
  return (
    <>
      <Slide
        subheading=''
        heading="Cine suntem?"
        description={
          <p>
            <strong>International Study Centre for Educational Opportunities</strong> (ISCEO) este un centru educațional de predare și învățare a limbii engleze pentru copii, tineri și adulți. Pe lângă <strong>instruirea lingvistică</strong> cu precădere, ne axăm pe <strong>dezvoltarea personală</strong> a cursanților noștri, oferindu-le oportunitatea de a trăi experiența unui tandem excepțional al Limbii Engleze cu Psihologia.
          </p>
        }
        imageSrc={Image1}
      />
      <Slide
        subheading=''
        heading="Ce facem?"
        description={
          <div>
            <p className='pb-4'>
              Învățarea unei limbi străine este un <strong>veritabil eveniment social</strong>, un proces diferit de învățarea oricărei alte discipline școlare. <strong>Lingvistica</strong>, domeniul cu o puternică încărcătură socială, reprezintă componentul esențial al identității unei persoane și al unei culturi. <strong>Limba</strong> ne ajută să definim realitățile ce ne înconjoară, să verbalizăm trăiri și senzații, să stabilim relații cu semenii noștri. Dincolo de gramatică, sintaxă, ortografie, învățarea unei limbi străine implică o schimbare a imaginii de sine, adoptarea de noi comportamente, a unei noi interpretări asupra lumii. Or, impactul ei asupra laturii sociale a personalității, la orice vârstă, este semnificativ.
            </p>
            <p>
              Iată de ce, la ISCEO, învățăm o <strong>Engleză cu Personalitate</strong>, una diversă și fascinantă, care să vă re-descopere propriul caracter, să vă contureze viziuni, să vă extindă orizonturi.
            </p>
          </div>
        }
        imageSrc={Image2}
        textOnLeft={false}
      />
      <QuestionSlide
        imageSrc={Image3}
        subheading=""
        heading="De ce să alegeți ISCEO?"
        description='8 motive să îndrăgiți ISCEO de la prima interacțiune:'
        faqs={[
          {
            question: "Limba Engleză cu Personalitate",
            answer:(
              <p>
                La ISCEO, metodele moderne din domeniul Psihologiei învățării unei limbi străine sunt adaptate la particularitățile individuale ale fiecărui elev. Sub îndrumarea profesionistă a psihologilor noștri, <strong>Limba Engleză lucrează în tandem cu Psihologia</strong>; de aceea la ISCEO se face Engleză cu Personalitate.
              </p>
            )
          },
          {
            question: "Oferte variate pentru fiecare vârstă",
            answer:(
              <p>
                ISCEO oferă calitate și continuitate printr-o <strong>varietate de servicii și oferte</strong>, create pentru vârste diferite - copii, preadolescenți / adolescenți, tineri, adulți, în vederea creșterii acestora atât în plan academic, cât și personal.
              </p>
            )
          }
        ]}
      />
      <QuestionSlide
        imageSrc={Image4}
        subheading=""
        heading=""
        description=''
        textOnLeft={false}
        faqs={[
          {
            question: "Oportunităţi educaţionale multiple",
            answer:(
              <p>
                Serviciile diverse și variate pe care le prestăm transformă limba engleză dintr-o materie școlară într-o <strong>motivație lingvistică</strong> aptă de a ne ghida discipolii în cadrul situațiilor reale de viață: de la lecții audio/video la discuții cu vorbitori nativi, atât pe parcursul anului academic, cât şi în cadrul școlilor de vacanță, cu sejur de zi și nocturn.
              </p>
            )
          },
          {
            question: "Parteneriat internațional",
            answer:(
              <p>
                ISCEO este un centru partener <strong>EnglishUK</strong>. Formăm o echipă internațională împreună cu partenerii noștri britanici, europeni și americani, activând în calitate de reprezentant oficial și, respectiv, consultant educațional pentru mai multe instituții de învățământ din străinătate. Din 2021, ISCEO este reprezentantul oficial al programelor internaționale <strong>HMC</strong> și <strong>ASSIST</strong> în R. Moldova.
              </p>
            )
          },
          {
            question: "Consultanță educaţională",
            answer:(
              <p>
                Experiența acumulată în Republica Moldova este în continuare implementată de discipolii noștri și peste hotarele țării - Marea Britanie, Europa şi SUA, în cadrul școlilor de vară, liceelor, colegiilor și universităților, programe și instituții la care aceștia aplică ca rezultat al consultanței pe care le-o oferim.
              </p>
            )
          }
        ]}
      />
      <QuestionSlide
        imageSrc={Image5}
        subheading=""
        heading=""
        description=''
        faqs={[
          {
            question: "Fără compromisuri în privința calității",
            answer:(
              <p>
                La ISCEO, se lucrează cu dăruire și pasiune. Am consolidat o echipă de profesori bine pregătiți, specializați în predarea limbii engleze și psihologie, cu instruire profesională și stagii în școli din Marea Britanie şi SUA; o echipă de oameni devotați, onești și loiali unui deziderat firesc – de a oferi elevilor noștri o <strong>educație de calitate, constructivă, performantă, fără compromisuri</strong>.
              </p>
            )
          },
          {
            question: "Dotări și utilități moderne",
            answer:(
              <p>
                Punem la dispoziția cursanților multiple instrumente și echipamente tehnice și logistice, pentru o practică optimă și eficientă deopotrivă pentru copii și adulți. Pentru lecții clasice și învățământ la distanță, avem amenajate săli de clasă spațioase, confortabile și perfect dotate: <strong>table școlare, calculatoare, proiectoare, table interactive și bibliotecă</strong> cu numeroase materiale de studiu. Pentru activități extra-curriculare, dispunem de <strong>stadion, teren de joacă pentru copii, săli de recreere și de divertisment</strong>, dar și oferim cursanților toate instrumentele și ustensilele folosite în cadrul atelierelor creative.
              </p>
            )
          },
          {
            question: "Grupuri mici de studiu",
            answer:(
              <p>
                Ora de limbă engleză, la ISCEO, se realizează în grupuri mici, a câte <strong>2-6 elevi</strong> fiecare, fapt care facilitează socializarea elevilor, oferind totodată spațiul temporal necesar manifestării individualității fiecăruia. <strong>Vârsta</strong> şi <strong>nivelul de dezvoltare al competențelor lingvistice</strong> sunt criterii fundamentale ce stau la baza formării grupurilor de studiu.
              </p>
            )
          }
        ]}
      />
    </>
  );
};
