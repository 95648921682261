import React from "react";
import { Switch, Route } from "react-router-dom";

import AcademicProgram from "./md-programmes/AcademicProgram.js";
import WeekendSchool from "./md-programmes/WeekendSchool.js";
import EnglishAfternoons from "./md-programmes/EnglishAfternoons.js";
import SummerSchool from "./md-programmes/SummerSchool.js";
import PupilsWorkshop from "./md-programmes/PupilsWorkshop.js";
import ParentsAcademy from "./md-programmes/ParentsAcademy.js";

export default function App() {
  return (
    <Switch>
      <Route path="/md-programmes/academic-program">
        <AcademicProgram />
      </Route>
      <Route path="/md-programmes/weekend-school">
        <WeekendSchool />
      </Route>
      <Route path="/md-programmes/english-afternoons">
        <EnglishAfternoons />
      </Route>
      <Route path="/md-programmes/summer-school">
        <SummerSchool />
      </Route>
      <Route path="/md-programmes/pupils-workshop">
        <PupilsWorkshop />
      </Route>
      <Route path="/md-programmes/parents-academy">
        <ParentsAcademy />
      </Route>
    </Switch>
  );
}
