import React from "react";
import tw from "twin.macro";

import Slide from "components/features/TwoColWithImage.js";
import QuestionSlide from "components/faqs/ExpandingText.js";

import Image1 from "../images/pages/careers/1.jpg";
import Image2 from "../images/pages/careers/2.jpg";
import Image3 from "../images/pages/careers/3.jpg";
import Image4 from "../images/pages/careers/4.jpg";

const Description = tw.div`mt-4 text-sm lg:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 text-2xl`;

export default () => {
  return (
    <>
      <Slide
        subheading=""
        heading="Angajăm Personalități, creștem Profesioniști!"
        description={
          <>
            <p>
              International Study Centre for Educational Opportunities este centrul lingvistic unde <strong>PERSONALITATEA</strong> contează cel mai mult! Este locul în care <strong>fiecare elev</strong> își dezlănțuie aspirațiile, își creionează visele și capătă încredere. Este mediul în care <strong>fiecare membru al echipei</strong> obține condițiile și sprijinul necesare pentru a prospera temeinic și continuu, personal și profesional.
            </p>
          </>
        }
        imageSrc={Image1}
      />
      <QuestionSlide
        imageSrc={Image2}
        subheading=""
        heading='Poziții vacante'
        description=''
        textOnLeft={false}
        faqs={[
          {
            question: 'Profesor de Limba Engleză',
            answer:(
              <p>
                Dacă sunteți profesor de limba engleză pentru copii și adulți, cu o bogată experiență în predare, vom fi onorați să vă punem la dispoziție toate instrumentele necesare, pentru a vă exercita pasiunea în cele mai bune condiții.
              </p>
            )
          },
          {
            question: 'Office Manager',
            answer:(
              <p>
                Dacă sunteți o persoană organizată și strategică, vom fi bucuroși să oferim cea mai eficientă și  productivă întrebuințare a abilităților Dvs., pentru a construi o ambianță plăcută și inspiratoare, pentru colegi și cursanți.
              </p>
            )
          },
          {
            question: 'Stagiar',
            answer:(
              <p>
                Dacă sunteți un tânăr profesionist la început de drum, vă vom ghida cu răbdare, deschidere și preocupare, astfel încât să avansați rapid, să dobândiți încredere și experiență.
              </p>
            )
          },
          {
            question: 'Voluntar',
            answer:(
              <p>
                Dacă sunteți pasionat de Limba Engleză, vă place să lucrați cu copiii și aveți un hobby pe care vreți să-l împărtășiți, vă așteptăm în Programul de Voluntariat, unde veți beneficia de sesiuni de training, instruire și formare, astfel încât să vă descoperiți adevărata menire.
              </p>
            )
          }
        ]}
      />
      <Slide
        subheading=""
        heading="Particularitățile job-ului"
        description={
          <>
            <p className="pb-4">
              La ISCEO, ne-am obișnuit să <strong>oferim</strong> mai mult decât
              să cerem. Dincolo de competențele profesionale, punem preț
              pe <strong>responsabilitate</strong>, <strong>asumare</strong>,{" "}
              <strong>onestitate</strong> și <strong>seriozitate</strong>.
              Apreciem tot ce este <strong>nou</strong> și{" "}
              <strong>inedit</strong>, încurajăm <strong>creativitatea</strong>,{" "}
              <strong>nonconformismul</strong> și{" "}
              <strong>eficiența</strong>.
            </p>
            <p className="pb-4">
              În colaborarea cu
              partenerii externi și în derularea programelor internaționale,{" "}
              <strong>flexibilitatea de a călători</strong> reprezintă un
              avantaj.
            </p>
            <p>
              În cadrul programelor, se lucrează în săli confortabile,
              armonioase, dotate cu echipamente tehnice performante, adaptate
              pentru lucrul în clasă și online, ustensile de birotică, manuale
              și cărți.
            </p>
          </>
        }
        imageSrc={Image3}
        textOnLeft={true}
      />
      <Slide
        subheading=""
        heading="Beneficii"
        description={
          <ul className="list-disc pl-5 text-left">
            <li>Training și suport, la începutul, cât și pe parcursul activității;</li>
            <li>Salariu și bonusuri motivante, direct proporționale cu timpul investit;</li>
            <li>Oportunități internaționale, datorită conexiunilor globale ale ISCEO;</li>
            <li>Gratuitate la serviciile ISCEO pentru Dvs. și familie; </li>
            <li>Mediu de lucru dotat cu materiale didactice și facilități moderne;</li>
            <li>Flexibilitate și abordare personalizată.</li>
          </ul>
        }
        imageSrc={Image4}
        textOnLeft={false}
      />
      <div className="pb-10 max-w-screen-xl mx-auto pt-10">
        <Description>
          <p className="text-center text-2xl pb-2">
            Dacă v-ați regăsit în misiunea și valorile noastre, lăsați-ne un
            mesaj cu CV-ul Dvs. la adresa de e-mail:
          </p>
          <p className="text-center text-2xl pb-2">
            <a href="mailto:office@isceo.md">office@isceo.md</a>
          </p>
          <p className="text-center text-2xl pb-2">
            Vom fi fericiți să ne cunoaștem la un interviu.
          </p>
        </Description>
      </div>
    </>
  );
};
