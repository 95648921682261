import React from "react";
import Slide from "components/features/TwoColWithMap.js";

export default () => {
  return (
    <div>
      <Slide
        subheading=""
        heading="Contact"
        description={
          <>
            <div className="pb-4">
              <div className="w-3/12 inline-block align-top">
                <strong>Telefon:</strong>
              </div>
              <div className="w-9/12 inline-block">
                <p>
                  <a href="tel:+37360551555">+373 60 551 555</a>
                </p>
                <p>ASSIST/HMCP:</p>
                  <p>
                    <a href="tel:+37360684000">+373 60 684 000</a>
                </p>
              </div>
            </div>
            <div className="pb-4">
              <div className="w-3/12 inline-block align-top">
                <strong>E-mail:</strong>
              </div>
              <div className="w-9/12 inline-block">
                <p>
                  <a href="mailto:office@isceo.md">office@isceo.md</a>
                </p>
                <p>ASSIST/HMCP:</p>
                <p>
                  <a href="mailto:scholarships@isceo.md">
                    scholarships@isceo.md
                  </a>
                </p>
              </div>
            </div>
          </>
        }
      />
    </div>
  );
};
