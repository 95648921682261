import React from "react";
import Slide from "components/features/TwoColWithImage.js";

import seagull from "../assets/jonathan-livingston-seagull.pdf";
import Image1 from "../images/pages/home/1.jpg";
import Image2 from "../images/pages/home/2.jpg";
import Image3 from "../images/pages/home/3.jpg";


export default () => {
  return (
    <>
      <Slide
        subheading=''
        heading="International Study Centre for Educational Opportunities"
        description={
          <p className='text-gray-300'>
            Dacă obișnuiți să vă depășiți limitele în permanență și să vă extindeți continuu aria de cunoaștere, dacă sunteți de părere că nu există tărâmuri inaccesibile pentru mintea și imaginația umană, dacă sunteți o personalitate interesată de studierea limbii engleze, educație, psihologie și științe, vă urăm <strong className='text-white'>Bine ați Venit!</strong>
          </p>
        }
        imageSrc={Image1}
        backgroundClass='dark-background'
        textClass='text-white'
      />
      <Slide
        subheading='Concept'
        heading=""
        description={
          <>
            <p>
              Ne-am inspirat întotdeauna din povești cu personaje puternice, curajoase, cu aspirații nelimitate și visuri mari. Am muncit neobosit pentru a ne îndeplini obiectivele și am rămas devotați unei misiuni mărețe. Am crezut cu tărie, asemeni <a href={seagull} target='_blank' rel="noreferrer">pescărușului Jonathan Livingston</a> al cărui poveste ne-a călăuzit mereu avântul creației, că:
            </p>
            <p className="quote">
              A îndrăzni să cunoști, sau a îndrăzni să te cunoști înseamnă, în primul rând, a-ţi permite libertatea de a accepta un gând nou, de a accepta că imposibilul de ieri poate deveni posibil, acesta fiind de fapt și adevăratul sens al evoluției - libertatea de a te depăși în fiecare clipă.
            </p>
            <p>
              Astfel, a fost zămislit <strong>ISCEO</strong>, dintr-o mare pasiune pentru <strong>Personalitate</strong>, în general, învățarea <strong>Limbii Engleze</strong>, în special, și o dorință infinită de auto-depășire a condiției umane din punct de vedere emoțional, psihologic și social.
            </p>
          </>
        }
        imageSrc={Image2}
        textOnLeft={false}
      />
      <Slide
        subheading='Misiune'
        heading=""
        description={
          <>
            <p>
              Dintre toate adevărurile minunate referitoare la suflet care au fost regăsite și scoase la lumină de către marii gânditori ai secolului XX, niciunul nu e mai îmbucurător sau mai plin de promisiuni divine și încredere decât acesta:
            </p>
            <p className="quote">
              Omul e stăpânul gândirii, modelatorul caracterului, făcătorul și modelatorul situațiilor, mediului și destinului. Ca ființă a Puterii, Inteligenței și Iubirii și stăpân al propriilor gânduri, omul deține cheia oricărei situații și are în sine acea forță transformatoare și regeneratoare prin care face din el însuși ceea ce vrea.
            </p>
            <p className='author'>
              James Allen (1864-1912)
            </p>
            <p>
              Suntem bucuroși să <strong>Vă împărtășim ceea ce cunoaștem, pentru a Vă ajuta să deveniți ceea ce puteți fi</strong>, astfel încât, asemeni <a href={seagull} target='_blank' rel="noreferrer">Pescărușului</a> lui Richard Bach, să fiți capabili să Vă ridicați deasupra mulțimii, valorificând-Vă la maxim potențialul cu care sunteți înzestrați.
            </p>
          </>
        }
        imageSrc={Image3}
      />
    </>
  );
};
