import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import Header from "components/headers/light.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import Home from "./pages/Home.js";
import International from "./pages/International.js";
import ISCEOBrand from "./pages/ISCEOBrand.js";
import HMCASSIST from "./pages/HMCASSIST.js";
import About from "./pages/About.js";
import Contact from "./pages/Contact.js";
import History from "./pages/History.js";
import Careers from "./pages/Careers.js";
import MDProgrammes from "./pages/MDProgrammes.js";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

export default function App() {
  return (
    <Router>
      <AnimationRevealPage disabled>
        <Header />
        <Switch>
          <Route path="/md-programmes">
            <MDProgrammes />
          </Route>
          <Route path="/international">
            <International />
          </Route>
          <Route path="/isceo-brand">
            <ISCEOBrand />
          </Route>
          <Route path="/hmc-assist">
            <HMCASSIST />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/history">
            <History />
          </Route>
          <Route path="/careers">
            <Careers />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
        <Footer />
      </AnimationRevealPage>
    </Router>
  );
}
