import React from "react";
import Slide from "components/features/TwoColWithImage.js";
import StepSlide from "components/features/ImageWithSteps.js";
import QuestionSlide from "components/faqs/ExpandingText.js";
import tw from "twin.macro";
import styled from "styled-components";
import {
  PrimaryButton as PrimaryButtonBase,
} from "components/misc/Buttons.js";

import Image1 from "../../images/pages/academic-program/1.jpg";
import Image2 from "../../images/pages/academic-program/2.jpg";
import Image3 from "../../images/pages/academic-program/3.jpg";
import Image4 from "../../images/pages/academic-program/4.jpg";
import { ACADEMIC_CHILDREN_LINK, ACADEMIC_ADULTS_LINK } from "../../constants";

const PrimaryButton = styled(PrimaryButtonBase)((props) => [
  tw`mt-4 md:mt-4 text-lg inline-block mx-auto md:mx-2 w-full text-center`,
  props.buttonRounded && tw`rounded-full`,
]);

const ButtonContainer = tw.div`max-w-lg mx-auto flex items-center mt-2 mb-8 md:flex-row flex-col`;

const ButtonContainerBottom = tw.div`max-w-lg mx-8 flex items-center mt-2 mb-8 md:flex-row flex-col md:mx-auto`;

export default () => {
  return (
    <>
      <Slide
        subheading=''
        heading='Programul Academic'
        description={
          <>
            <p className='pb-4'>
              La ISCEO, oferim un <strong>program de studiere a limbii engleze</strong> pe durata întregului an academic, un curs complementar curriculei școlare, menit să îmbunătățească considerabil nivelul competențelor lingvistice la engleză al copilului Dvs.
            </p>
            <p>
              Vă îndemnăm să încredințați profesioniștilor noștri socializarea copilului Dvs. de la o vârstă fragedă, care, prin expertiza solidă și viabilă dobândită în <strong>psihologie</strong>, <strong>pedagogie</strong> și <strong>limbă engleză</strong>, vor asigura nu doar dezvoltarea competențelor lingvistice ale elevului, dar și <strong>o experiență educativă și socială inedită</strong>.
            </p>
            <ButtonContainer>
            <PrimaryButton as="a" href={ACADEMIC_CHILDREN_LINK}>
              '24-'25 Copii
            </PrimaryButton>
            <PrimaryButton as="a" href={ACADEMIC_ADULTS_LINK}>
              '24-'25 Adulți
            </PrimaryButton>

          </ButtonContainer>
          </>
        }
        imageSrc={Image1}
      />
      <Slide
        subheading=''
        heading=''
        description={
          <>
            <p className='pb-4'>
              Vă prezentăm un program diferit de oricare alt curs de limba engleză în sensul clasic, deoarece aplică un <strong>tratament</strong> și o <strong>abordare individualizate</strong>, în conformitate cu particularitățile psiho-emoționale și sociale ale elevului.
            </p>
            <p>
              Pe lângă aptitudinile lingvistice, elevii, în cadrul instruirii ISCEO, vor exersa <strong>capacități de lucru în echipă</strong>, <strong>abilități de integrare socială</strong>, <strong>dezvoltare personală</strong>, <strong>atenție</strong>, <strong>memorie</strong> și alte însușiri imperios necesare atât în viața de școlar, cât și în cadrul tuturor activităților cotidiene.
            </p>
          </>
        }
        imageSrc={Image2}
        textOnLeft={false}
      />
      <StepSlide
        subheading=''
        heading={
          <>
            Mod de organizare
          </>
        }
        imageSrc={Image3}
        steps={[
          {
            heading: 'Vârsta',
            description: '6 - 17 ani și adulți'
          },
          {
            heading: 'Perioada de realizare',
            description: 'septembrie - mai, cu respectarea tuturor vacanțelor școlare'
          },
          {
            heading: 'Desfășurare',
            description: '2-3 activități cu durata 60-90 min. pe săptămână, în funcție de vârstă și preferințe'
          },
          {
            heading: 'Număr de cursanți în grup',
            description: '2-6 cursanți'
          }
        ]}
      />
      <QuestionSlide
        imageSrc={Image4}
        subheading=""
        heading='Particularitățile cursului'
        description=''
        textOnLeft={false}
        faqs={[
          {
            question: 'Literatura editată de Oxford și Cambridge',
            answer:(
              <p>
                Nenumăratele materiale didactice din librăria ISCEO sunt o resursă complementară eforturilor profesioniștilor noștri și un instrument valoros în activitatea pe care o desfășurăm. Manualele însoțite de caiete de lucru, CD-uri și discuri audio/video trezesc și mențin optimul motivațional al elevilor, pe toată durata cursului. Mai mult, acestea reprezintă o resursă prețioasă pentru activitatea independentă, în afara orelor de curs.
              </p>
            )
          },
          {
            question: 'Oferte variate pentru fiecare vârstă',
            answer:(
              <p>
                Oferim calitate și continuitate prin <strong>oportunitățile educaționale multiple</strong>, special concepute pentru categorii diferite de vârstă - copii, preadolescenți/adolescenți, tineri, adulți, în vederea creșterii și dezvoltării acestora atât în plan academic, cât și personal.
              </p>
            )
          },
          {
            question: 'Pregătirea pentru examene naționale (Bacalaureat) și internaționale (TOEFL și IELTS)',
            answer:(
              <p>
                Program ce vine în sprijinul solicitanților de a-și îmbunătăți rezultatele școlare în R. Moldova și/sau de a-și continua studiile în străinătate.
              </p>
            )
          }
        ]}
      />
      <ButtonContainerBottom style={{marginTop: '-3rem'}}>
      <PrimaryButton as="a" href={ACADEMIC_CHILDREN_LINK}>
              '24-'25 Copii
            </PrimaryButton>
      <PrimaryButton as="a" href={ACADEMIC_ADULTS_LINK}>
              '24-'25 Adulți
            </PrimaryButton>

      </ButtonContainerBottom>
    </>
  );
};
