import React from "react";
import Slide from "components/features/TwoColWithImage.js";

import Image1 from "../images/pages/history/1.jpg";
import Image2 from "../images/pages/history/2.jpg";
import Image3 from "../images/pages/history/3.jpg";
import Image4 from "../images/pages/history/4.jpg";

export default () => {
  return (
    <>
      <Slide
        subheading=''
        heading="Istoric"
        description={
          <>
            <p className='pb-4'>
              Activitatea ISCEO își are sorgintea în anul <strong>2004</strong>, când o echipă de profesori cu experiență internațională, specializați în predarea limbii engleze și psihologie au organizat <strong>prima școală de tip britanic</strong> în R. Moldova. Proiectul întitulat <strong>„Opportunities for Everyone”</strong> cu caracter distractiv, instructiv și educativ a reprezentat o premieră pentru țara noastră, deoarece a îmbinat armonios relaxarea și distracția cu instruirea și educația, formulă ce a generat un impact deosebit asupra copiilor și părinților.
            </p>
            <p>
              În cele <strong>4 ediții succesive</strong> (2004-2007), pe durata a două săptămâni de program, echipa noastră a dăscălit aproximativ <strong>500 de copii</strong>, pentru ca în 2008, la dorința discipolilor noștri, să organizăm prima călătorie în Marea Britanie.
            </p>
          </>
        }
        imageSrc={Image1}
      />
      <Slide
        subheading='2008-2012 - originea unei deschideri internaționale perene'
        heading=""
        description={
          <>
            <p className='pb-4'>
              Următorii cinci ani (2008-2012) au reprezentat cinci trepte valoroase în creșterea noastră profesională, timp în care am stabilit <strong>relații solide și durabile</strong> de parteneriat cu instituții educaționale importante din <strong>Marea Britanie</strong>, <strong>Europa</strong> și <strong>SUA</strong>.
            </p>
            <p>
              În vara anului 2021, ISCEO a devenit reprezentantul oficial al programelor internaționale HMC și ASSIST în R. Moldova, proiecte de mare anvergură mondială, ce oferă elevilor inteligenți burse de studiu în cele mai prestigioase școli din afară, dar și o deschidere internațională de excepție.
            </p>
          </>
        }
        imageSrc={Image2}
        textOnLeft={false}
      />
      <Slide
        subheading='2013 - o decadă de activitate ISCEO'
        heading=""
        description={
          <>
            <p>
              În anul 2013, an în care am marcat decada activității ISCEO, alături de proiectele în desfășurare (școli de vacanță în R. Moldova și Regatul Unit), am lansat <strong>noi oportunități educaționale</strong>: școli de weekend, cursuri personalizate de studiere a limbii engleze, atât pentru copii, cât și pentru adulți. De asemenea, în parteneriat cu <strong>Studio Cambridge</strong>, am lansat concursul național <strong>„English Skills Championship”</strong> (ESC), continuat în 2014, în parteneriat cu <strong>King's School Ely</strong>. Câștigătorii premiului întâi au obținut burse de studiu la Școli de Vară din Marea Britanie.
            </p>
          </>
        }
        imageSrc={Image3}
      />
      <Slide
        subheading='Poveste făurită cu grijă, din 2004 până în prezent'
        heading=""
        description={
          <>
            <p>
              Patru ani de activitate în R. Moldova, în calitate de școală de vară de tip britanic, cinci ani în Regatul Unit al Mării Britanii și Irlandei de Nord, ca partener al Studio Cambridge, și din 2012 sub insigna unui centru educațional cu programe și oferte variate – această experiență, prin <strong>viabilitate</strong> și <strong>continuitate</strong>, reprezintă <strong>garantul calității și profesionalismului</strong> oferit. Până în prezent am dăscălit mii de elevi, mulți dintre care ne reprezintă cu onoare și peste hotarele țării.
            </p>
          </>
        }
        imageSrc={Image4}
        textOnLeft={false}
      />
    </>
  );
};
