import React from "react";
import MainFeature1 from "components/features/TwoColWithImage.js";
import StepSlide from "components/features/ImageWithSteps.js";
import QuestionSlide from "components/faqs/ExpandingText.js";
import tw from "twin.macro";
import styled from "styled-components";
import {
  PrimaryButton as PrimaryButtonBase,
} from "components/misc/Buttons.js";

import Image1 from "../../images/pages/weekend-school/1.jpg";
import Image2 from "../../images/pages/weekend-school/2.jpg";
import Image3 from "../../images/pages/weekend-school/3.jpg";
import { WEEKEND_SCHOOL_LINK } from "../../constants";

const PrimaryButton = styled(PrimaryButtonBase)((props) => [
  tw`mt-4 md:mt-4 text-xl inline-block mx-auto md:mx-0 w-full text-center`,
  props.buttonRounded && tw`rounded-full`,
]);

const ButtonContainer = tw.div`max-w-xs mx-auto flex items-center mt-2 mb-8`;

export default () => {
  return (
    <>
      <MainFeature1
        subheading=''
        heading="Weekend School"
        description={
          <>
            <p className='pb-4'>
              Școala de Weekend este un program de studiere a limbii engleze, în care <strong>performanța academică și eficiența procesului de învățare</strong> sunt compensate prin <strong>activități creative, relaxante, dar și de divertisment</strong>. După principiul îmbinării plăcutului cu utilul, farmecul zilelor active și captivante de vacanță trăiește tot anul.
            </p>
            <p>
              Activitățile noastre de weekend sunt dedicate tuturor copiilor care, pe lângă orele de studiu conform programului școlar, își doresc să dezvolte și să îmbunătățească <strong>aptitudini și competențe extrașcolare</strong>, precum limba engleză avansată, pictura, teatrul, sportul, arta culinară etc.
            </p>
            <ButtonContainer>
            <PrimaryButton as="a" href={WEEKEND_SCHOOL_LINK}>
              Înregistrare '24-'25
            </PrimaryButton>
          </ButtonContainer>
          </>
        }
        imageSrc={Image1}
      />
      <StepSlide
        subheading=''
        heading={
          <>
            Mod de organizare
          </>
        }
        textOnLeft={false}
        imageSrc={Image2}
        steps={[
          {
            heading: "Vârsta",
            description: "5-12 ani"
          },
          {
            heading: "Perioada de realizare",
            description: "septembrie - mai, cu pauze pe parcursul vacanțelor școlare, timp de cel puțin un semestru"
          },
          {
            heading: "Desfășurare",
            description: "10:00-14:00, în fiecare zi de sâmbătă"
          },
          {
            heading: "Număr maxim de cursanți în grup",
            description: "12 cursanți"
          },
          {
            heading: "Alimentație opțională",
            description: "Prânz alcătuit cu grijă și rigurozitate, inspirat din preferințele elevilor de până acum"
          },
        ]}
      />
      <QuestionSlide
        imageSrc={Image3}
        subheading=""
        heading='Particularitățile cursului'
        description=''
        faqs={[
          {
            question: "Limba engleză extracurriculară",
            answer:(
              <p>
                În timpul Școlii de Weekend, limba engleză se experimentează printr-o diversitate de culori, gusturi și mirosuri. Ea se cântă, se dansează și se teatralizează. Adevărurile ei se povestesc și se scriu, se dezbat și se verifică prin experimente. <strong>Engleza prinde viață</strong> în sălile noastre de clasă.
              </p>
            )
          },
          {
            question: "Învățăm prin comunicare",
            answer:(
              <p>
                Mediul de imersiune lingvistică creat în cadrul Școlii de Weekend le oferă elevilor noștri <strong>multiple posibilități de practicare a limbii</strong> sub ghidarea atât a profesorilor autohtoni, cât și a vorbitorilor nativi.
              </p>
            )
          },
          {
            question: "Învățăm prin joc",
            answer:(
              <p>
              Cursanții ISCEO sunt implicați într-o multitudine de activități de masă și de echipă, organizate în sălile de clasă, în aer liber, cât și în cadrul atelierelor psihologice. Acestea vizează, pe lângă componenta educativă, <strong>divertismentul de calitate</strong> și <strong>petrecerea timpului liber eficient</strong> și productiv.
              </p>
            )
          }
        ]}
      />
      <ButtonContainer style={{marginTop: '-3rem'}}>
        <PrimaryButton as="a" href={WEEKEND_SCHOOL_LINK}>
          Înregistrare '24-'25
        </PrimaryButton>
      </ButtonContainer>
    </>
  );
};
